$(function() {
  if ($("body").hasClass("home")) {
    $(function() {
      $(".sp-openbtn").on("click", function() {
        $(".catch__inr").toggleClass("open");
        $(".sp-wrapper").slideToggle();
      });
      $(window).on("scroll", function() {
        var position = 100;
        var scroll = $(window).scrollTop();
        var windowHeight = $(window).height();
        console.log("yattaze");
        if (scroll - windowHeight > position) {
          $(".c-header").addClass("fixed");
        } else {
          $(".c-header").removeClass("fixed");
        }
      });
    });
    var windowWidth = $(window).width();
    var windowSm = 812;
    if (windowWidth <= windowSm) {
      //横幅640px以下のとき（つまりスマホ時）に行う処理を書く
    } else {
    }
  }
});
