$(function() {
  if ($("body").hasClass("page-template-page-graduates")) {
    $(function() {
      $(".sp-openbtn").on("click", function(e) {
        $(this)
          .parent()
          .toggleClass("open");
        $(this)
          .prev()
          .slideToggle();
        e.preventDefault();
      });
    });

    var windowWidth = $(window).width();
    var windowSm = 812;
    if (windowWidth <= windowSm) {
      //横幅640px以下のとき（つまりスマホ時）に行う処理を書く
    } else {
    }
  }
});
